import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { TbChevronLeft } from "react-icons/tb";

function Ausstellungen() {
    const API_CALL = "https://www.oberlik-burtscher.at/wp/wp-json/wp/v2/pages/";

    const API_CALL_AUSSTELLUNG = "https://www.oberlik-burtscher.at/wp/wp-json/wp/v2/posts?filter[cat]=4";

    const [content, setContent] = useState([]);
    const [ausstellungen, setAusstellungen] = useState([]);

    useEffect(() => {
        async function fetchData() {
            // You can await here
            fetch(API_CALL)
                .then((response) => response.json())
                .then((result) => {
                    const data = result;
                    console.log(data);

                    document.querySelector("#spinner").classList.add("noShow");
                    // You can await here
                    // document.getElementById("ausstellungenHolder").innerHTML = data[3].content.rendered;
                });
        }
        async function fetchDataAusstellung() {
            // You can await here
            fetch(API_CALL_AUSSTELLUNG)
                .then((response) => response.json())
                .then((result) => {
                    const data = result;
                    console.log(data);
                    Array.from(document.getElementsByClassName("blogoverviewTitel")).map((e, i) => {
                        e.innerHTML = data[i].title.rendered;
                    });
                    Array.from(document.getElementsByClassName("blogoverviewContent")).map((e, i) => {
                        e.innerHTML = data[i].excerpt.rendered;
                    });
                    Array.from(document.getElementsByClassName("blogoverviewDate")).map((e, i) => {
                        e.innerHTML = data[i].date;
                    });
                    Array.from(document.getElementsByClassName("blogImg")).map((e, i) => {
                        if (e.better_featured_image) {
                            e.style.backgroundImage = "url(" + data[i].better_featured_image.source_url + ")";
                        }
                    });
                    Array.from(document.getElementsByClassName("blogoverviewLink")).map((e, i) => {
                        e.href = "blog/" + data[i].slug;
                    });
                });
        }

        fetchData();
        fetchDataAusstellung();
        console.log("useeffect");
    }, []);

    useEffect(() => {
        fetch("https://www.oberlik-burtscher.at/wp/wp-json/wp/v2/ausstellung/?per_page=100")
            .then((response) => response.json())
            .then((result) => {
                const data = result.reverse();
                setAusstellungen(data);
                console.log(data.reverse());
            });
    }, []);
    let open = true;

    function handleClick(e) {
        console.log(
            e.currentTarget.parentNode.children[1].style.display === "none",
            e.currentTarget.parentNode.children[1].style.display
        );
        let img = e.currentTarget.parentNode.children[1];

        if (open) {
            img.style.display = "none";
            open = false;
        } else {
            img.style.display = "flex";
            // img.style.justifyContent = "center";
            open = true;
        }
    }

    return (
        <div className="ausstellungen" id="ausstellungen">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="blogImg"></div>
                    </div>
                    <div className="col-lg-8 col-md-6 col-sm-6 col-12">
                        <h4 class="blogoverviewTitel mb-4"></h4>

                        <div className="blogoverviewDate"></div>
                        <div className="blogoverviewContent"></div>
                        <a className="blogoverviewLink" href=""></a>
                    </div>
                </div>
                <hr className="content" />

                <div className="row">
                    <div id="spinner" className="spinnerWrapperGallery d-flex justify-content-center h-100">
                        <div class="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                    <div className="col">
                        <h3 className="mb-5 mt-4 content">Ausstellungen</h3>
                    </div>
                    <div className="col-12 mt-3" id="ausstellungenHolder">
                        {ausstellungen &&
                            ausstellungen.map((e, i) => {
                                if (e.better_featured_image) {
                                    return (
                                        <div className="wrapperImg row">
                                            {/* <div className="img col-4">
                                                <img
                                                    style={{ maxWidth: "15rem", marginBottom: "1rem" }}
                                                    src={e.better_featured_image.source_url}
                                                    alt=""
                                                />
                                            </div> */}
                                            <div className="text col-12 col-md-8">
                                                <div
                                                    className="ausstellung d-flex "
                                                    onClick={(e) => {
                                                        handleClick(e);
                                                    }}
                                                >
                                                    <span
                                                        style={{ textDecoration: "underline", cursor: "pointer" }}
                                                        className=""
                                                    >
                                                        {" "}
                                                        {parse(e.content.rendered)}
                                                    </span>
                                                    <span className="text-xl"> {/* <TbChevronLeft /> */}</span>
                                                </div>
                                                <div className="img col-12 col-md-8 border-bottom border-left border-right mb-4 ">
                                                    <img
                                                        style={{ maxWidth: "15rem", marginBottom: "1rem" }}
                                                        src={e.better_featured_image.source_url}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return <div className="ausstellung">{parse(e.content.rendered)}</div>;
                                }

                                // e.better_featured_image ? (

                                //     <div className="wrapper">
                                //         <div className="img">
                                //             <img src={e.better_featured_image} alt="" />
                                //         </div>
                                //         <div className="text">
                                //         <div className="ausstellung">{parse(e.content.rendered)}</div>
                                //         </div>
                                //     </div>

                                // ) : ( <div className="ausstellung">{parse(e.content.rendered)}</div>)
                            })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Ausstellungen;
