import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import left from "./img/left.svg";
import right from "./img/right.svg";
import BlogOverview from "./blog_overview";
import Popup from "./popup";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

function Aquarell() {
    const API_CALL = "https://www.oberlik-burtscher.at/wp/wp-json/wp/v2/verschiedenes?per_page=100";

    const [content, setContent] = useState([]);

    useEffect(() => {
        async function fetchData() {
            fetch(API_CALL)
                .then((response) => response.json())
                .then((result) => {
                    const data = result;
                    console.log(data);
                    document.querySelector("#spinner").classList.add("noShow");
                    document.querySelector(".wrap").style.display = "block";
                    // You can await here

                    console.log(data);
                    setContent(data);
                    let imgLen = data.length;
                    console.log(imgLen);
                    let thumnbails = document.querySelector("#thumbNails");

                    function imageShifter(plusminus) {
                        getActive = parseInt(getActive) + parseInt(plusminus);
                        console.log(getActive);
                        document.querySelector("#bigImg").style.backgroundImage =
                            images[parseInt(getActive)].style.backgroundImage;
                        document.querySelector("#bildTitel").innerHTML = data[parseInt(getActive)].acf.titel_des_bildes;
                        document.querySelector("#groesse").innerHTML = data[parseInt(getActive)].acf.groesse;
                        document.querySelector("#jahr").innerHTML = data[parseInt(getActive)].acf.jahr;
                        document.querySelector("#bildBeschreibung").innerHTML =
                            data[parseInt(getActive)].acf.beschreibung;
                        document.querySelector("#content").innerHTML = data[parseInt(getActive)].content.rendered;
                        images.map((e) => e.classList.remove("active"));
                        images[parseInt(getActive)].classList.add("active");
                    }

                    for (let i = 0; i < imgLen; i++) {
                        let newDiv = document.createElement("div");
                        thumnbails.append(newDiv);
                        thumnbails.children[i].classList.add("thumbnail");
                        thumnbails.children[i].dataset.id = i;
                        thumnbails.children[0].classList.add("active");
                        thumnbails.children[i].style.backgroundImage = "url(" + data[i].acf.bild_hinzufugen + ")";
                        console.log(data[i]);
                    }
                    document.querySelector("#bigImg").style.backgroundImage =
                        "url(" + data[0].acf.bild_hinzufugen + ")";

                    document.querySelector("#bildTitel").innerHTML = data[0].acf.titel_des_bildes;
                    // document.querySelector("#malTechnik").innerHTML =
                    //     data[0].acf.maltechnik;
                    document.querySelector("#groesse").innerHTML = data[0].acf.groesse;
                    document.querySelector("#jahr").innerHTML = data[0].acf.jahr;
                    document.querySelector("#bildBeschreibung").innerHTML = data[0].acf.beschreibung;
                    document.querySelector("#content").innerHTML = data[0].content.rendered;

                    if (data[0].acf.verkauft == true) {
                        document.querySelector(".interesse").style.display = "none";
                        document.querySelector(".sold").style.display = "block";
                    } else {
                        document.querySelector(".sold").style.display = "none";
                        document.querySelector(".interesse").style.display = "block";
                    }

                    saver(data);
                    let images = Array.from(document.getElementsByClassName("thumbnail"));
                    let getActive = 0;
                    console.log(images);
                    images.map((e) => {
                        e.addEventListener("click", function () {
                            document.querySelector("#bigImg").style.backgroundImage = this.style.backgroundImage;
                            document.querySelector("#bildTitel").innerHTML = data[this.dataset.id].acf.titel_des_bildes;
                            // document.querySelector("#malTechnik").innerHTML =
                            //     data[this.dataset.id].acf.maltechnik;
                            document.querySelector("#groesse").innerHTML = data[this.dataset.id].acf.groesse;
                            document.querySelector("#jahr").innerHTML = data[this.dataset.id].acf.jahr;
                            document.querySelector("#content").innerHTML = data[this.dataset.id].content.rendered;
                            document.querySelector("#bildBeschreibung").innerHTML =
                                data[this.dataset.id].acf.beschreibung;

                            if (data[this.dataset.id].acf.verkauft == true) {
                                document.querySelector(".interesse").style.display = "none";
                                document.querySelector(".sold").style.display = "block";
                            } else {
                                document.querySelector(".sold").style.display = "none";
                                document.querySelector(".interesse").style.display = "block";
                            }

                            images.map((e) => e.classList.remove("active"));
                            this.classList.add("active");
                            getActive = this.dataset.id;
                            let parentLen = document.querySelector("#thumbnailParent").offsetWidth;
                            // if (e.offsetLeft >= parentLen - 180) {
                            //     console.log("SCROOOOLL ME");
                            //     document
                            //         .querySelector("#thumbnailParent")
                            //         .scrollTo(e.offsetLeft + 80, 0);
                            // }

                            // console.log(parentLen, e.offsetLeft);
                        });
                    });

                    document
                        .querySelector(".interesse")
                        .querySelector(".wrap")
                        .addEventListener("click", function () {
                            document.querySelector(".wrapAll").style.display = "block";

                            document.querySelector("#popupImg").style.backgroundImage = document.querySelector(
                                ".active"
                            ).style.backgroundImage;
                            document.querySelector("#popupImg").dataset.id =
                                data[parseInt(document.querySelector(".active").dataset.id)].slug;
                            document.querySelector("#idField").value = document.querySelector("#popupImg").dataset.id;
                            document.querySelector("#categoryField").value =
                                data[parseInt(document.querySelector(".active").dataset.id)].type;
                        });

                    function closePopup() {
                        document.querySelector(".wrapAll").style.display = "none";
                    }

                    document.querySelector(".closer").addEventListener("click", closePopup);

                    document.querySelector(".overlay").addEventListener("click", closePopup);

                    document.querySelector("#right").addEventListener("click", function () {
                        if (parseInt(getActive) + 1 < imgLen) {
                            imageShifter("+1");
                        }
                        if (data[getActive].acf.verkauft == true) {
                            document.querySelector(".interesse").style.display = "none";
                            document.querySelector(".sold").style.display = "block";
                        } else {
                            document.querySelector(".sold").style.display = "none";
                            document.querySelector(".interesse").style.display = "block";
                        }
                    });

                    document.querySelector("#left").addEventListener("click", function () {
                        if (parseInt(getActive) - 1 >= 0) {
                            imageShifter("-1");
                        }
                        if (data[getActive].acf.verkauft == true) {
                            document.querySelector(".interesse").style.display = "none";
                            document.querySelector(".sold").style.display = "block";
                        } else {
                            document.querySelector(".sold").style.display = "none";
                            document.querySelector(".interesse").style.display = "block";
                        }
                    });

                    document.addEventListener("keydown", function (e) {
                        const key = e.key;
                        console.log(key);

                        images.map((e) => {
                            if (e.classList.contains("active")) {
                                getActive = e.dataset.id;
                            }
                        });
                        console.log(getActive, typeof getActive);
                        switch (e.key) {
                            case "ArrowLeft":
                                if (parseInt(getActive) - 1 >= 0) {
                                    imageShifter("-1");
                                }
                                if (data[getActive].acf.verkauft == true) {
                                    document.querySelector(".interesse").style.display = "none";
                                    document.querySelector(".sold").style.display = "block";
                                } else {
                                    document.querySelector(".sold").style.display = "none";
                                    document.querySelector(".interesse").style.display = "block";
                                }
                                break;
                            case "ArrowRight":
                                if (parseInt(getActive) + 1 < imgLen) {
                                    imageShifter("+1");
                                }
                                if (data[getActive].acf.verkauft == true) {
                                    document.querySelector(".interesse").style.display = "none";
                                    document.querySelector(".sold").style.display = "block";
                                } else {
                                    document.querySelector(".sold").style.display = "none";
                                    document.querySelector(".interesse").style.display = "block";
                                }
                                break;
                            case "ArrowUp":
                                // Up pressed
                                break;
                            case "ArrowDown":
                                // Down pressed
                                break;
                        }
                    });
                });
            // ...
        }
        fetchData();
        let pos = 0;

        function wheeler(e) {
            let delta = (e.deltaY || -e.wheelDelta || e.detail) >> 10 || 1;
            delta = delta * -300;
            document.querySelector("#thumbnailParent").scrollLeft -= delta;
            // safari needs also this
            document.querySelector("#thumbnailParent").scrollLeft -= delta;
            e.preventDefault();
        }

        document.querySelector("#thumbnailParent").addEventListener("mouseover", function () {
            document.addEventListener("wheel", wheeler);
        });
        // document
        //     .querySelector("#thumbnailParent")
        //     .addEventListener("mouseleave", function () {
        //         console.log("maus raus");
        //         document
        //             .querySelector("#thumbnailParent")
        //             .removeEventListener("wheel", wheeler);
        //     });

        console.log("useeffect");
    }, []);

    function saver(theData) {
        let jsonFile = theData;
        console.log(jsonFile);
    }

    return (
        <div className="gallery">
            <Popup />
            {/* <div id="test">Home</div> */}
            <div className="container-fluid position-relative">
                <div className="container ">
                    <div id="left" className="left">
                        <img src={left} alt="" />
                    </div>
                    <div id="right" className="right">
                        <img src={right} alt="" />
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-7 d-flex justify-content-center imgHolder">
                            <div id="bigImg"></div>
                        </div>
                        <div className="col-12 col-sm-5 detailHolder">
                            <div className="row">
                                <div className="col submenu d-flex justify-content-between w-100">
                                    <Link to="/acryl">
                                        <div className="galleryLink ">Acryl</div>
                                    </Link>
                                    <Link to="/monotypien">
                                        <div className="galleryLink ">Monotypien</div>
                                    </Link>
                                    <Link to="/oel">
                                        <div className="galleryLink">Öl/Öl-Pastell</div>
                                    </Link>
                                    <Link to="/aquarell">
                                        <div className="galleryLink activeMenu">Aquarell & mehr</div>
                                    </Link>
                                </div>
                            </div>
                            <div className="row h-100">
                                <div id="spinner" className="spinnerWrapperGallery d-flex justify-content-center h-100">
                                    <div className="lds-ellipsis">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                                <div className="col">
                                    <h3 id="bildTitel" className="mt-50 mb-4"></h3>
                                    {/* <p id="malTechnik"></p> */}
                                    <p id="groesse"></p>
                                    <p id="jahr"></p>
                                    <p id="bildBeschreibung"></p>
                                    <p id="content"></p>
                                    <div className="sold mt-3">verkauft</div>
                                    <div className="interesse text-center mt-3">
                                        <div className="wrap">Anfrage</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="mt-4 mb-4" />
                    <div className="row">
                        <div id="thumbnailParent" className="col d-flex thumbnailParent">
                            <div id="thumbNails" className="thumbnailWrapper"></div>
                        </div>
                    </div>
                    {/* <BlogOverview /> */}
                </div>
            </div>
        </div>
    );
}

export default Aquarell;
