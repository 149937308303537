import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Rou, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import BlogOverview from "./blog_overview";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";

import Breadcrumbs from "./breadcrumbs";

function Blogdetail() {
    const [content, setContent] = useState(null);
    const [overview, setOverview] = useState(null);
    const params = useParams();
    const rowRef = useRef();
    const imgRef = useRef();

    // urlSetter().then((result) => console.log(result));

    useEffect(() => {
        const API_CALL = "https://www.oberlik-burtscher.at/wp/wp-json/wp/v2/posts?slug=" + params.id;
        const API_CALL_CAT = "https://www.oberlik-burtscher.at/wp/wp-json/wp/v2/posts?filter[cat]=4";
        const API_CALL_DEV = "https://www.atelierbuchner.at/oberlik_burtscher/wp/wp-json/wp/v2/posts?slug=" + params.id;
        const API_CALL_CAT_DEV = "https://www.atelierbuchner.at/oberlik_burtscher/wp/wp-json/wp/v2/posts?filter[cat]=4";
        async function fetchData() {
            // You can await here
            const response = await fetch(API_CALL);
            const data = await response.json();
            setContent(data);
            console.log(data, "BUBUB");
            document.getElementById("firstHeader").innerHTML = data[0].title.rendered;
        }
        fetchData();
        fetch(API_CALL_CAT)
            .then((res) => res.json())
            .then((data) => setOverview(data));
        console.log("useeffect");
        console.log(imgRef);
    }, []);

    return (
        <div id="blog" className="blog">
            <div id="blogHolder" className="container">
                <Breadcrumbs slug={params.id} />
                <div className="row">
                    <div className="col">
                        <h4 id="firstHeader" class="blogHeader ">
                            {content && content[0].title.rendered}
                        </h4>
                        <hr className="mb-5" />
                    </div>
                </div>
                <div className="row pb-4" ref={rowRef}>
                    <div className="col-md-8 col-sm-12 pr-md-4">
                        <div id="blogcontent" class="blogcontent">
                            {content && ReactHtmlParser(content[0].content.rendered)}
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <div id="blogImage" class="">
                            {content && content[0].better_featured_image && (
                                <img src={content[0].better_featured_image.source_url} ref={imgRef}></img>
                            )}
                        </div>
                    </div>
                </div>
                <hr />{" "}
                <div className="d-block">
                    <h5>Weitere Einträge</h5>
                </div>
                <div className="row mt-3">
                    {overview &&
                        overview.map((e, i) =>
                            e.slug !== params.id && i <= 3 ? (
                                <div className="col-md-4 col-sm-12 mb-4" key={i}>
                                    <div className="featuredImg mb-4">
                                        <a href={e.slug}>
                                            {e.better_featured_image ? (
                                                <div
                                                    style={{
                                                        backgroundImage:
                                                            "url(" + e.better_featured_image.source_url + ")",
                                                    }}
                                                    alt=""
                                                />
                                            ) : null}
                                        </a>
                                    </div>
                                    {/* {console.log(imgRef.current.offsetHeight)} */}
                                    <span className="d-none">
                                        {/* {(rowRef.current.style.minHeight = imgRef.current.offsetHeight + "px")} */}
                                    </span>
                                    <a href={e.slug}>
                                        <h6 id="overviewLink" className="mt-3">
                                            {e.title.rendered}
                                        </h6>
                                    </a>
                                </div>
                            ) : null
                        )}
                </div>
            </div>
        </div>
    );
}

export default Blogdetail;
