import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link } from "react-router-dom";

function Blog() {
    const API_CALL = "https://www.oberlik-burtscher.at/wp/wp-json/wp/v2/posts?filter[cat]=4";

    const API_CALL_DEV = "https://www.atelierbuchner.at/oberlik_burtscher/wp/wp-json/wp/v2/posts?filter[cat]=4";

    const [content, setContent] = useState([]);
    const [firstContent, setFirstContent] = useState("");

    function construct(data) {
        let newDiv = document.createElement("div");
        newDiv.classList = "row mb-4";
        newDiv.appendChild(document.createElement("div"));
        newDiv.appendChild(document.createElement("div"));
        newDiv.children[0].classList = "col-md-3 col-sm-12";
        newDiv.children[0].appendChild(document.createElement("div"));
        newDiv.children[0].children[0].classList = "blogImage mb-3";
        newDiv.children[1].classList = "col-md-7 col-sm-12";
        newDiv.children[1].appendChild(document.createElement("div"));
        newDiv.children[1].appendChild(document.createElement("div"));
        newDiv.children[1].appendChild(document.createElement("a"));
        newDiv.children[1].appendChild(document.createElement("hr"));
        newDiv.children[1].children[0].classList = "blogHeader mb-3";
        newDiv.children[1].children[1].classList = "blogExcerpt";
        newDiv.children[1].children[2].classList = "blogMore";
        newDiv.children[1].children[2].innerHTML = "Mehr...";
        newDiv.children[1].children[2].href = "blog/" + data;
        document.querySelector("#blogHolder").append(newDiv);
    }

    async function urlSetter() {
        let result = await content[0].slug;
        console.log(result);
        return "/blog/" + result;
    }

    // urlSetter().then((result) => console.log(result));

    useEffect(() => {
        async function fetchData() {
            // You can await here
            const response = await fetch(API_CALL);
            const data = await response.json();
            setContent(data);
            setFirstContent(data[0].slug);

            // fetch(API_CALL)
            //     .then((response) => response.json())
            //     .then((result) => {
            // You can await here
            // const response = await fetch(API_CALL);
            // const data = await response.json();
            // let data = result;
            console.log(data, "LALA");
            setContent(data);
            console.log(data.length);
            for (let i = 0; i < data.length; i++) {
                construct(data[i].slug);
            }

            await Array.from(document.getElementsByClassName("blogImage")).map((e, i) => {
                if (e.better_featured_image) {
                    e.style.backgroundImage = "url(" + data[i].better_featured_image.source_url + ")";
                }
            });
            await Array.from(document.getElementsByClassName("blogHeader")).map((e, i) => {
                e.innerHTML = data[i].title.rendered;
            });
            await Array.from(document.getElementsByClassName("blogExcerpt")).map((e, i) => {
                e.innerHTML = data[i].excerpt.rendered;
            });
            // document.querySelector("#daLink").href = "blog/" + data[0].slug;
            // });

            // Array.from(
            //     document.getElementsByClassName("blogoverviewContent")
            // ).map((e, i) => {
            //     e.innerHTML = data[i].excerpt.rendered;
            // });
            // Array.from(document.getElementsByClassName("blogoverviewDate")).map(
            //     (e, i) => {
            //         e.innerHTML = data[i].date;
            //     }
            // );
            // Array.from(document.getElementsByClassName("blogImg")).map(
            //     (e, i) => {
            //         e.style.backgroundImage =
            //             "url(" + data[i].better_featured_image.source_url + ")";
            //     }
            // );

            // ...
        }
        fetchData();
        console.log("useeffect");
    }, []);

    function createBlog() {
        let newDiv = document.createElement("div");
        newDiv.classList = "row";
        newDiv.appendChild(document.createElement("div"));
        newDiv.children[0].classList = "col-5";
        newDiv.children[0].appendChild(document.createElement("div"));
        newDiv.children[0].appendChild(document.createElement("div"));
        newDiv.children[0].appendChild(document.createElement("div"));
        newDiv.children[0].children[0].classList = "blogHeader";

        document.querySelector("blogHolder").append(newDiv);
    }

    return (
        <div id="blog" className="blog">
            <div id="blogHolder" className="container">
                {/* <div className="row">
                    <div className="col-3">
                        <div id="firstImg" className="blogImage"></div>
                    </div> */}
                {console.log(content)}
                {/* <div id="blogHolder" className="blogHolder">
                        {/* <div className="row">
                            <div className="col-5">
                                <div className="blogHeader"></div>
                                <div className="blogExcerpt"></div>
                                <div className="blogMore"></div>
                            </div>
                        </div> */}
                {/* </div> */}
                <div id="firstBlog" className="col-5">
                    {/* <h4 id="firstHeader" class="blogHeader"></h4> */}
                    {/* <p id="firstExcerpt" class="blogExcerpt"></p> */}
                    {content.map((e, i) => console.log(e))}
                    {console.log(firstContent)}
                    {/* {content.map((e, i) => ( */}
                    <Link id="daLink" to={firstContent}>
                        {/* <Link to={urlSetter().then((result) => result)}> */}
                        {/* <div id="firstMore" className="blogMore"> */}
                        {/* mehr...
                            </div> */}
                    </Link>
                    {/* ))} */}
                </div>
            </div>
        </div>
    );
}

export default Blog;
