import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link } from "react-router-dom";

function Datenschutz() {
    return (
        <div className="datenschutz" id="datenschutz">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h1>Datenschutzerklärung</h1>
                        <h4>Einleitung</h4>
                        <p>
                            Mit der folgenden Datenschutzerklärung möchten wir
                            Sie darüber aufklären, welche Arten Ihrer
                            personenbezogenen Daten (nachfolgend auch kurz als
                            "Daten“ bezeichnet) wir zu welchen Zwecken und in
                            welchem Umfang verarbeiten. Die Datenschutzerklärung
                            gilt für alle von uns durchgeführten Verarbeitungen
                            personenbezogener Daten, sowohl im Rahmen der
                            Erbringung unserer Leistungen als auch insbesondere
                            auf unseren Webseiten, in mobilen Applikationen
                            sowie innerhalb externer Onlinepräsenzen, wie z.B.
                            unserer Social-Media-Profile (nachfolgend
                            zusammenfassend bezeichnet als "Onlineangebot“).
                        </p>
                        <p>
                            Die verwendeten Begriffe sind nicht
                            geschlechtsspezifisch.
                        </p>
                        <p>Stand: 28. September 2020</p>
                        <h4>Inhaltsübersicht</h4>
                        <ul className="index">
                            <li>
                                <a className="index-link" href="#m14">
                                    Einleitung
                                </a>
                            </li>
                            <li>
                                <a className="index-link" href="#m3">
                                    Verantwortlicher
                                </a>
                            </li>
                            <li>
                                <a className="index-link" href="#mOverview">
                                    Übersicht der Verarbeitungen
                                </a>
                            </li>
                            <li>
                                <a className="index-link" href="#m13">
                                    Maßgebliche Rechtsgrundlagen
                                </a>
                            </li>
                            <li>
                                <a className="index-link" href="#m136">
                                    Präsenzen in sozialen Netzwerken (Social
                                    Media)
                                </a>
                            </li>
                            <li>
                                <a className="index-link" href="#m42">
                                    Begriffsdefinitionen
                                </a>
                            </li>
                        </ul>
                        <h4 id="m3">Verantwortlicher</h4>
                        <p>
                            Brigitte Oberlik-Burtscher
                            <br />
                            <br />
                            1190 Wien Straßergasse 15
                            <br />
                            3053 Brand-Laaben Pyrath 3<br />
                        </p>
                        <p>
                            <strong>Vertretungsberechtigte Personen:</strong>{" "}
                            Brigitte Oberlik-Burtscher
                        </p>
                        <p>
                            <strong>E-Mail-Adresse:</strong>{" "}
                            brigitte@oberlik-burtscher.at
                        </p>
                        <p>
                            <strong>Telefon:</strong> +43 676 490 8866
                        </p>
                        <h4 id="mOverview">Übersicht der Verarbeitungen</h4>
                        <p>
                            Die nachfolgende Übersicht fasst die Arten der
                            verarbeiteten Daten und die Zwecke ihrer
                            Verarbeitung zusammen und verweist auf die
                            betroffenen Personen.
                        </p>
                        <h5>Arten der verarbeiteten Daten</h5>
                        <ul>
                            <li>Bestandsdaten (z.B. Namen, Adressen).</li>
                            <li>
                                Inhaltsdaten (z.B. Texteingaben, Fotografien,
                                Videos).
                            </li>
                            <li>Kontaktdaten (z.B. E-Mail, Telefonnummern).</li>
                            <li>
                                Meta-/Kommunikationsdaten (z.B.
                                Geräte-Informationen, IP-Adressen).
                            </li>
                            <li>
                                Nutzungsdaten (z.B. besuchte Webseiten,
                                Interesse an Inhalten, Zugriffszeiten).
                            </li>
                        </ul>
                        <h5>Kategorien betroffener Personen</h5>
                        <ul>
                            <li>
                                Nutzer (z.B. Webseitenbesucher, Nutzer von
                                Onlinediensten).
                            </li>
                        </ul>
                        <h5>Zwecke der Verarbeitung</h5>
                        <ul>
                            <li>Kontaktanfragen und Kommunikation.</li>
                            <li>Remarketing.</li>
                            <li>
                                Tracking (z.B. interessens-/verhaltensbezogenes
                                Profiling, Nutzung von Cookies).
                            </li>
                        </ul>
                        <h5 id="m13">Maßgebliche Rechtsgrundlagen</h5>
                        <p>
                            Im Folgenden teilen wir die Rechtsgrundlagen der
                            Datenschutzgrundverordnung (DSGVO), auf deren Basis
                            wir die personenbezogenen Daten verarbeiten, mit.
                            Bitte beachten Sie, dass zusätzlich zu den
                            Regelungen der DSGVO die nationalen
                            Datenschutzvorgaben in Ihrem bzw. unserem Wohn- und
                            Sitzland gelten können. Sollten ferner im Einzelfall
                            speziellere Rechtsgrundlagen maßgeblich sein, teilen
                            wir Ihnen diese in der Datenschutzerklärung mit.
                        </p>
                        <ul>
                            <li>
                                <strong>
                                    Berechtigte Interessen (Art. 6 Abs. 1 S. 1
                                    lit. f. DSGVO)
                                </strong>
                                - Die Verarbeitung ist zur Wahrung der
                                berechtigten Interessen des Verantwortlichen
                                oder eines Dritten erforderlich, sofern nicht
                                die Interessen oder Grundrechte und
                                Grundfreiheiten der betroffenen Person, die den
                                Schutz personenbezogener Daten erfordern,
                                überwiegen.
                            </li>
                        </ul>
                        <p>
                            <strong>
                                Nationale Datenschutzregelungen in Österreich
                            </strong>
                            : Zusätzlich zu den Datenschutzregelungen der
                            Datenschutz-Grundverordnung gelten nationale
                            Regelungen zum Datenschutz in Österreich. Hierzu
                            gehört insbesondere das Bundesgesetz zum Schutz
                            natürlicher Personen bei der Verarbeitung
                            personenbezogener Daten (Datenschutzgesetz – DSG).
                            Das Datenschutzgesetz enthält insbesondere
                            Spezialregelungen zum Recht auf Auskunft, zum Recht
                            auf Richtigstellung oder Löschung, zur Verarbeitung
                            besonderer Kategorien personenbezogener Daten, zur
                            Verarbeitung für andere Zwecke und zur Übermittlung
                            sowie zur automatisierten Entscheidungsfindung im
                            Einzelfall.
                        </p>
                        <h4 id="m136">
                            Präsenzen in sozialen Netzwerken (Social Media)
                        </h4>
                        <p>
                            Wir unterhalten Onlinepräsenzen innerhalb sozialer
                            Netzwerke und verarbeiten in diesem Rahmen Daten der
                            Nutzer, um mit den dort aktiven Nutzern zu
                            kommunizieren oder um Informationen über uns
                            anzubieten.
                        </p>
                        <p>
                            Wir weisen darauf hin, dass dabei Daten der Nutzer
                            außerhalb des Raumes der Europäischen Union
                            verarbeitet werden können. Hierdurch können sich für
                            die Nutzer Risiken ergeben, weil so z.B. die
                            Durchsetzung der Rechte der Nutzer erschwert werden
                            könnte.
                        </p>
                        <p>
                            Ferner werden die Daten der Nutzer innerhalb
                            sozialer Netzwerke im Regelfall für Marktforschungs-
                            und Werbezwecke verarbeitet. So können z.B. anhand
                            des Nutzungsverhaltens und sich daraus ergebender
                            Interessen der Nutzer Nutzungsprofile erstellt
                            werden. Die Nutzungsprofile können wiederum
                            verwendet werden, um z.B. Werbeanzeigen innerhalb
                            und außerhalb der Netzwerke zu schalten, die
                            mutmaßlich den Interessen der Nutzer entsprechen. Zu
                            diesen Zwecken werden im Regelfall Cookies auf den
                            Rechnern der Nutzer gespeichert, in denen das
                            Nutzungsverhalten und die Interessen der Nutzer
                            gespeichert werden. Ferner können in den
                            Nutzungsprofilen auch Daten unabhängig der von den
                            Nutzern verwendeten Geräte gespeichert werden
                            (insbesondere, wenn die Nutzer Mitglieder der
                            jeweiligen Plattformen sind und bei diesen
                            eingeloggt sind).
                        </p>
                        <p>
                            Für eine detaillierte Darstellung der jeweiligen
                            Verarbeitungsformen und der
                            Widerspruchsmöglichkeiten (Opt-Out) verweisen wir
                            auf die Datenschutzerklärungen und Angaben der
                            Betreiber der jeweiligen Netzwerke.
                        </p>
                        <p>
                            Auch im Fall von Auskunftsanfragen und der
                            Geltendmachung von Betroffenenrechten weisen wir
                            darauf hin, dass diese am effektivsten bei den
                            Anbietern geltend gemacht werden können. Nur die
                            Anbieter haben jeweils Zugriff auf die Daten der
                            Nutzer und können direkt entsprechende Maßnahmen
                            ergreifen und Auskünfte geben. Sollten Sie dennoch
                            Hilfe benötigen, dann können Sie sich an uns wenden.
                        </p>
                        <ul className="m-elements">
                            <li>
                                <strong>Verarbeitete Datenarten:</strong>{" "}
                                Bestandsdaten (z.B. Namen, Adressen),
                                Kontaktdaten (z.B. E-Mail, Telefonnummern),
                                Inhaltsdaten (z.B. Texteingaben, Fotografien,
                                Videos), Nutzungsdaten (z.B. besuchte Webseiten,
                                Interesse an Inhalten, Zugriffszeiten),
                                Meta-/Kommunikationsdaten (z.B.
                                Geräte-Informationen, IP-Adressen).
                            </li>
                            <li>
                                <strong>Betroffene Personen:</strong> Nutzer
                                (z.B. Webseitenbesucher, Nutzer von
                                Onlinediensten).
                            </li>
                            <li>
                                <strong>Zwecke der Verarbeitung:</strong>{" "}
                                Kontaktanfragen und Kommunikation, Tracking
                                (z.B. interessens-/verhaltensbezogenes
                                Profiling, Nutzung von Cookies), Remarketing.
                            </li>
                            <li>
                                <strong>Rechtsgrundlagen:</strong> Berechtigte
                                Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
                            </li>
                        </ul>
                        <p>
                            <strong>
                                Eingesetzte Dienste und Diensteanbieter:
                            </strong>
                        </p>
                        <ul className="m-elements">
                            <li>
                                <strong>Instagram:</strong> Soziales Netzwerk;
                                Dienstanbieter: Instagram Inc., 1601 Willow
                                Road, Menlo Park, CA, 94025, USA,
                                Mutterunternehmen: Facebook, 1 Hacker Way, Menlo
                                Park, CA 94025, USA; Website:
                                <a
                                    href="https://www.instagram.com"
                                    target="_blank"
                                >
                                    https://www.instagram.com
                                </a>
                                ; Datenschutzerklärung:
                                <a
                                    href="https://instagram.com/about/legal/privacy"
                                    target="_blank"
                                >
                                    https://instagram.com/about/legal/privacy
                                </a>
                                .
                            </li>
                        </ul>
                        <h4 id="m42">Begriffsdefinitionen</h4>
                        <p>
                            In diesem Abschnitt erhalten Sie eine Übersicht über
                            die in dieser Datenschutzerklärung verwendeten
                            Begrifflichkeiten. Viele der Begriffe sind dem
                            Gesetz entnommen und vor allem im Art. 4 DSGVO
                            definiert. Die gesetzlichen Definitionen sind
                            verbindlich. Die nachfolgenden Erläuterungen sollen
                            dagegen vor allem dem Verständnis dienen. Die
                            Begriffe sind alphabetisch sortiert.
                        </p>
                        <ul className="glossary">
                            <li>
                                <strong>Personenbezogene Daten:</strong>{" "}
                                "Personenbezogene Daten“ sind alle
                                Informationen, die sich auf eine identifizierte
                                oder identifizierbare natürliche Person (im
                                Folgenden "betroffene Person“) beziehen; als
                                identifizierbar wird eine natürliche Person
                                angesehen, die direkt oder indirekt,
                                insbesondere mittels Zuordnung zu einer Kennung
                                wie einem Namen, zu einer Kennnummer, zu
                                Standortdaten, zu einer Online-Kennung (z.B.
                                Cookie) oder zu einem oder mehreren besonderen
                                Merkmalen identifiziert werden kann, die
                                Ausdruck der physischen, physiologischen,
                                genetischen, psychischen, wirtschaftlichen,
                                kulturellen oder sozialen Identität dieser
                                natürlichen Person sind.
                            </li>
                            <li>
                                <strong>Remarketing:</strong> Vom "Remarketing“
                                bzw. "Retargeting“ spricht man, wenn z.B. zu
                                Werbezwecken vermerkt wird, für welche Produkte
                                sich ein Nutzer auf einer Webseite interessiert
                                hat, um den Nutzer auf anderen Webseiten an
                                diese Produkte, z.B. in Werbeanzeigen, zu
                                erinnern.
                            </li>
                            <li>
                                <strong>Tracking:</strong> Vom "Tracking“
                                spricht man, wenn das Verhalten von Nutzern über
                                mehrere Onlineangebote hinweg nachvollzogen
                                werden kann. Im Regelfall werden im Hinblick auf
                                die genutzten Onlineangebote Verhaltens- und
                                Interessensinformationen in Cookies oder auf
                                Servern der Anbieter der Trackingtechnologien
                                gespeichert (sogenanntes Profiling). Diese
                                Informationen können anschließend z.B.
                                eingesetzt werden, um den Nutzern Werbeanzeigen
                                anzuzeigen, die voraussichtlich deren Interessen
                                entsprechen.
                            </li>
                            <li>
                                <strong>Verantwortlicher:</strong> Als
                                "Verantwortlicher“ wird die natürliche oder
                                juristische Person, Behörde, Einrichtung oder
                                andere Stelle, die allein oder gemeinsam mit
                                anderen über die Zwecke und Mittel der
                                Verarbeitung von personenbezogenen Daten
                                entscheidet, bezeichnet.
                            </li>
                            <li>
                                <strong>Verarbeitung:</strong> "Verarbeitung"
                                ist jeder mit oder ohne Hilfe automatisierter
                                Verfahren ausgeführte Vorgang oder jede solche
                                Vorgangsreihe im Zusammenhang mit
                                personenbezogenen Daten. Der Begriff reicht weit
                                und umfasst praktisch jeden Umgang mit Daten,
                                sei es das Erheben, das Auswerten, das
                                Speichern, das Übermitteln oder das Löschen.
                            </li>
                        </ul>
                        <p className="seal">
                            <a
                                href="https://datenschutz-generator.de/?l=de"
                                title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
                                target="_blank"
                                rel="noopener noreferrer nofollow"
                            >
                                Erstellt mit kostenlosem
                                Datenschutz-Generator.de von Dr. Thomas Schwenke
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Datenschutz;
