import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link } from "react-router-dom";

function GalleryModule() {
    // const API_CALL =
    //     "http://localhost/headlessWordpressTest/wp-json/wp/v2/pages/";

    // const [content, setContent] = useState([]);

    // useEffect(() => {
    //     async function fetchData() {
    //         // You can await here
    //         const response = await fetch(API_CALL);
    //         const data = await response.json();
    //         console.log(data);
    //         setContent(data);
    //         document.getElementById("bioPic").style.backgroundImage =
    //             "url(" + data[0].acf.bild_bio + ")";
    //         document.getElementById("mainHeader").innerHTML =
    //             data[0].acf.header;
    //         document.getElementById("bioContentStart").innerHTML =
    //             data[0].acf.bio_text_startseite;
    //         // ...
    //     }
    //     fetchData();
    //     console.log("useeffect");
    // }, []);

    return (
        <div id="galleryModule" className="galleryModule mt-10">
            {/* <div id="test">Home</div> */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12 pr-5">
                        <Link to="/acryl">
                            <div id="acrylOverview" className="overview"></div>
                            <div className="mt-3 text-center overvidewDesc">
                                Acryl
                            </div>
                        </Link>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-3 col-12 pr-5">
                        <Link to="/monotypien">
                            <div
                                id="monotypienOverview"
                                className=" overview"
                            ></div>
                            <div className="mt-3 text-center overvidewDesc">
                                Monotypie
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12 pr-5">
                        <Link to="/oel">
                            <div id="oelOverview" className="overview"></div>
                            <div className="mt-3 text-center overvidewDesc">
                                Öl / Öl - Pastell
                            </div>
                        </Link>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-3 col-12 pr-5">
                        <Link to="/aquarell">
                            <div
                                id="verschiedenesOverview"
                                className="overview"
                            ></div>
                            <div className="mt-3 text-center overvidewDesc">
                                Aquarell & mehr
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GalleryModule;
