import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link } from "react-router-dom";

function Impressum() {
    return (
        <div className="datenschutz" id="datenschutz">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h4>Impressum</h4>
                        <p>
                            <b>
                                Informationen und Offenlegung gemäß &sect;5 (1) ECG, &sect; 25 MedienG, &sect; 63 GewO
                                und &sect; 14 UGB
                            </b>
                        </p>
                        <p>
                            <b>Webseitenbetreiber:</b> Brigitte Oberlik-Burtscher
                        </p>
                        <p>
                            <b>Anschrift:</b> Dennweg 43/3, 1190 Wien
                        </p>
                        <p>
                            <b>UID-Nr:</b> <br />
                            <b>Gewerbeaufsichtbehörde:</b> <br />
                            <b>Mitgliedschaften:</b>
                        </p>
                        <p>
                            <b>Kontaktdaten:</b> <br />
                            Telefon: +43 676 490 8866 <br />
                            Email: brigitte@oberlik-burtscher.at <br />
                            Fax:
                        </p>

                        <p>
                            <b>Anwendbare Rechtsvorschrift:</b> www.ris.bka.gv.at <br />
                            <b>Berufsbezeichnung:</b>
                        </p>
                        <p>
                            <b>Online Streitbeilegung:</b> Verbraucher, welche in Österreich oder in einem sonstigen
                            Vertragsstaat der ODR-VO niedergelassen sind, haben die Möglichkeit Probleme bezüglich dem
                            entgeltlichen Kauf von Waren oder Dienstleistungen im Rahmen einer Online-Streitbeilegung
                            (nach OS, AStG) zu lösen. Die Europäische Kommission stellt eine Plattform hierfür bereit:
                            https://ec.europa.eu/consumers/odr
                        </p>
                        <p>
                            <b>Urheberrecht:</b> Die Inhalte dieser Webseite unterliegen, soweit dies rechtlich möglich
                            ist, diversen Schutzrechten (z.B dem Urheberrecht). Jegliche Verwendung/Verbreitung von
                            bereitgestelltem Material, welche urheberrechtlich untersagt ist, bedarf schriftlicher
                            Zustimmung des Webseitenbetreibers.
                        </p>
                        <p>
                            <b>Haftungsausschluss:</b> Trotz sorgfältiger inhaltlicher Kontrolle übernimmt der
                            Webseitenbetreiber dieser Webseite keine Haftung für die Inhalte externer Links. Für den
                            Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich. Sollten Sie
                            dennoch auf ausgehende Links aufmerksam werden, welche auf eine Webseite mit rechtswidriger
                            Tätigkeit/Information verweisen, ersuchen wir um dementsprechenden Hinweis, um diese nach §
                            17 Abs. 2 ECG umgehend zu entfernen.
                            <br />
                            Die Urheberrechte Dritter werden vom Betreiber dieser Webseite mit größter Sorgfalt
                            beachtet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten
                            wir um einen entsprechenden Hinweis. Bei Bekanntwerden derartiger Rechtsverletzungen werden
                            wir den betroffenen Inhalt umgehend entfernen.
                        </p>
                        <p>
                            Quelle:
                            <b>
                                <a href="https://advolist.at/anwalt/ort/Graz/1">Rechtsanwalt Graz - Advolist</a>
                                in Zusammenarbeit mit
                                <a href="https://checksimply.com">Online Marketing - Checksimply</a>
                            </b>
                        </p>
                        <p>
                            {" "}
                            <strong>Fotografie:</strong> Maria Hollunder
                            <br />
                            <a href="https://www.hollunder.at/">www.hollunder.at</a>
                        </p>
                        <p>
                            {" "}
                            <strong>Fotografie:</strong> Matias Damjanovic
                        </p>
                        <p>
                            <strong>Webdesign:</strong> Johannes Buchner
                            <br />
                            <a href="https://www.atelierbuchner.at">www.atelierbuchner.at</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Impressum;
