import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link } from "react-router-dom";

function Biografie() {
    const API_CALL = "https://www.oberlik-burtscher.at/wp/wp-json/wp/v2/pages/";

    const [content, setContent] = useState([]);
    const [width, setWidth] = useState([]);
    const [dims, setDims] = useState({});
    const [myData, setMyData] = useState(null);

    const colRef = useRef();

    function getDims(src) {
        let img = new Image();
        img.src = src;
        console.log(img.width);
        return {
            width: img.width,
            height: img.height,
        };
    }

    useEffect(() => {
        async function fetchData() {
            // You can await here
            fetch(API_CALL)
                .then((response) => response.json())
                .then((result) => {
                    const data = result;
                    console.log(data);
                    setMyData(data);
                    document.querySelector("#spinner").classList.add("noShow");
                    // You can await here
                    console.log(data[1].acf.bild1.url);
                    // document.getElementById("img1").style.backgroundImage = "url(" + data[1].acf.bild1.url + ")";
                    // document.getElementById("img2").style.backgroundImage = "url(" + data[1].acf.bild1_copy.url + ")";
                    // document.getElementById("img3").style.backgroundImage = "url(" + data[1].acf.bild_3.url + ")";
                    document.getElementById("bioHolder").innerHTML = data[1].content.rendered;
                    setDims(getDims(data[1].acf.bild1.url));
                });
        }

        fetchData();
        console.log("useeffect");
    }, []);

    useEffect(() => {
        console.log(myData);
    }, [myData]);

    return (
        <div className="biografie" id="biografie">
            <div className="container">
                <div className="row">
                    <div ref={colRef} className="col-lg-6 col-md-4 col-sm-4 col-12 imgs">
                        {/* <div style={{ height: dims.height + "px" }} id="img1"></div> */}
                        {myData && (
                            <>
                                <div id="img1">
                                    <img src={myData[1].acf.bild1.url} />
                                </div>
                                <div id="img2">
                                    <img src={myData[1].acf.bild1_copy.url} />
                                </div>
                                <div id="img3">
                                    <img src={myData[1].acf.bild_3.url} />
                                </div>
                            </>
                        )}
                    </div>

                    <div className="col-lg-6 col-md-8 col-sm-8 col-12">
                        <h2 className="mb-5 bioHeader" id="mainHeader">
                            Brigitte<br></br> Oberlik-Burtscher
                        </h2>
                        <div id="bioHolder">
                            <div id="spinner" className="spinnerWrapperGallery d-flex justify-content-center h-100">
                                <div class="lds-ellipsis">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                            {/* <p id="datum" className="col-3 datum"></p>
                            <p id="details" className="col-9 details"></p>
                            <p id="datum2" className="col-3 datum"></p>
                            <p id="details2" className="col-9 details"></p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Biografie;
