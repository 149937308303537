import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link } from "react-router-dom";

function Success() {
    return (
        <div className="success mt-10 " id="success">
            <h1>TEST</h1>
        </div>
    );
}

export default Success;
