import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link } from "react-router-dom";

function AusstellungView() {
    const API_CALL = "https://www.oberlik-burtscher.at/wp/wp-json/wp/v2/posts?filter[cat]=4";
    const API_CALL_DEV = "https://www.atelierbuchner.at/oberlik_burtscher/wp/wp-json/wp/v2/posts?filter[cat]=4";

    const [content, setContent] = useState([]);

    function decodeHtml(html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    useEffect(() => {
        async function fetchData() {
            // You can await here
            const response = await fetch(API_CALL);
            const data = await response.json();
            console.log(data);
            setContent(data);
            Array.from(document.getElementsByClassName("blogoverviewTitel")).map((e, i) => {
                e.innerHTML = data[i].title.rendered;
            });
            Array.from(document.getElementsByClassName("blogoverviewContent")).map((e, i) => {
                e.innerHTML = decodeHtml(data[i].content.rendered);
                console.log(data[i].excerpt.rendered);
            });

            Array.from(document.getElementsByClassName("blogoverviewDate")).map((e, i) => {
                e.innerHTML = data[i].date;
            });
            Array.from(document.getElementsByClassName("blogImg")).map((e, i) => {
                console.log(e);
                if (e.better_featured_image) {
                    e.style.backgroundImage = "url(" + data[i].better_featured_image.source_url + ")";
                }
            });
            Array.from(document.getElementsByClassName("blogoverviewLink")).map((e, i) => {
                e.href = "blog/" + data[i].slug;
            });

            // ...
        }
        fetchData();
        console.log("useeffect");
    }, []);

    return (
        <div id="blogOverview" className="BlogOverview mt-10">
            {/* <div id="test">Home</div> */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="blogImg"></div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <h4 class="blogoverviewTitel mb-4"></h4>

                        <div className="blogoverviewDate"></div>
                        <div className="blogoverviewContent"></div>
                        <a className="blogoverviewLink" href=""></a>
                        <Link to="./ausstellungen">
                            <div className="mt-4 blogLink">mehr...</div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AusstellungView;
