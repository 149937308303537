import React, { useState, useEffect } from "react";
import logo from "./img/logo_neu.jpg";
import instagram from "./img/instagram.png";
import hamburger from "./img/hamburger.svg";
import close from "./img/close.svg";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function MobileMenu() {
    const [hamburgerIcon, setHamburgerIcon] = useState(false);

    useEffect(() => {
        let mobilemenuwrapper = document.querySelector("#mobileMenuWrapper");
        let hamburgericon = document.querySelector("#hamburger");
        let closeicon = document.querySelector("#close");
        let menuItem = Array.from(document.getElementsByClassName("menuItem"));

        document.querySelector("#hamburger").addEventListener("click", function () {
            mobilemenuwrapper.style.display = "block";
            mobilemenuwrapper.classList.remove("fade-out");
            mobilemenuwrapper.classList.add("fade-in");
            hamburgericon.style.display = "none";
            closeicon.style.display = "inline-block";
        });

        closeicon.addEventListener("click", function () {
            mobilemenuwrapper.classList.remove("fade-in");
            mobilemenuwrapper.classList.add("fade-out");
            hamburgericon.style.display = "inline-block";
            closeicon.style.display = "none";
            setTimeout(() => {
                mobilemenuwrapper.style.display = "none";
            }, 250);
        });

        menuItem.map((e) => {
            e.addEventListener("click", function () {
                mobilemenuwrapper.classList.remove("fade-in");
                mobilemenuwrapper.classList.add("fade-out");
                hamburgericon.style.display = "inline-block";
                closeicon.style.display = "none";
                setTimeout(() => {
                    mobilemenuwrapper.style.display = "none";
                }, 250);
            });
        });
    }, []);

    return (
        <div className="mobileMenu mb-8">
            {/* <div id="test">Home</div> */}
            <div className="container-fluid menuBorder">
                <div className="container">
                    <div className="row pt-3 mb-3">
                        <div className="col-4 d-flex align-items-center">
                            <Link to="/">
                                {/* <img src={logo} alt="" /> */}
                                <div className="menuItem home">Home</div>
                            </Link>
                        </div>
                        <div className="col-8 text-right">
                            <img id="hamburger" src={hamburger} alt="" />
                            <img id="close" src={close} alt="" />
                        </div>
                        <div id="mobileMenuWrapper">
                            <div className="menuWrap">
                                <Link to="/acryl">
                                    <div id="galerieSub" className="menuItem">
                                        Galerie
                                    </div>
                                </Link>
                                <div id="galerieSubMenu" className="subMenu">
                                    <Link to="/acryl">
                                        <div className="menuItem">Acryl</div>
                                    </Link>
                                    <Link to="/monotypien">
                                        <div className="menuItem">Monotypie</div>
                                    </Link>
                                    <Link to="/oel">
                                        <div className="menuItem">Öl / Öl-Pastell</div>
                                    </Link>
                                    <Link to="/aquarell">
                                        <div className="menuItem">Aquarell</div>
                                    </Link>{" "}
                                </div>
                                <Link to="/blog">
                                    <div className="menuItem">Blog</div>
                                </Link>
                                <Link to="/ausstellungen">
                                    <div className="menuItem">Ausstellungen</div>
                                </Link>
                                <Link to="/biografie">
                                    <div className="menuItem">Biografie</div>
                                </Link>
                                <Link to="/notizen">
                                    <div className="menuItem">Worte</div>
                                </Link>
                                <HashLink to="#kontakt">
                                    <div className="menuItem">Kontakt</div>
                                </HashLink>
                            </div>
                        </div>
                        {/* <div className="col-7 d-flex justify-content-between align-items-center">
                        

                            <div className="menuWrap">
                               
                                </Link>
                                <div id="galerieSubMenu" className="subMenu">
                                    <Link to="/test/acryl">
                                        <div className="menuItem">Acryl</div>
                                    </Link>
                                    <Link to="/test/monotypien">
                                        <div className="menuItem">
                                            Monotypie
                                        </div>
                                    </Link>
                                    <Link to="/test/oel">
                                        <div className="menuItem">
                                            Öl / Öl-Pastell
                                        </div>
                                    </Link>
                                    <Link to="/test/aquarell">
                                        <div className="menuItem">Aquarell</div>
                                    </Link>
                                </div>
                            </div>

                        
                        </div> */}
                        {/* <div className="col-1 d-flex justify-content-end align-items-center">
                            <Link to="https://www.instagram.com">
                                <img src={instagram} alt="" />
                            </Link>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MobileMenu;
