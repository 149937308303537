import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import closer from "./img/close.svg";

function Popup() {
    return (
        <div className="wrapAll">
            <div className="overlay"></div>
            <div className="buyWrapper">
                <div className="closer">
                    <img src={closer} alt="" />
                </div>
                <div className="buyBox container">
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <p id="popupID"></p>
                            <div id="popupImg"></div>
                        </div>
                        <div className="col-12 col-md-6">
                            <h3>Interesse an diesem Bild?</h3>
                            <p>Kontaktieren Sie mich über dieses Formular:</p>
                            <form  method="post" action="sendbuymail.php" id="buyForm">
                            <input type="text" name="buyEmail" id="buyEmail" placeholder="Email" />
                            <textarea
                                type="text"
                                id="buyMessage"
                                name="buyMessage"
                                placeholder="Ihre Nachricht"
                            />
                            <input type="hidden" name="idField" id="idField" />
                            <input type="hidden" name="categoryField" id="categoryField" />
                            <button type="submit" name="submit" id="submit" className="kontaktBtn">abschicken</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Popup;
