import React, { useState, useEffect } from "react";
import logo from "./img/logo_neu.jpg";
import instagram from "./img/instagram.png";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function Menu() {
    useEffect(() => {
        document.querySelector("#galerieSub").addEventListener("mouseover", function () {
            document.querySelector("#galerieSubMenu").style.display = "block";
        });
        document.querySelector("#galerieSubMenu").addEventListener("mouseover", function () {
            document.querySelector("#galerieSubMenu").style.display = "block";
        });
        document.querySelector("#galerieSub").addEventListener("mouseleave", function () {
            document.querySelector("#galerieSubMenu").style.display = "none";
        });
        document.querySelector("#galerieSubMenu").addEventListener("mouseleave", function () {
            document.querySelector("#galerieSubMenu").style.display = "none";
        });
        document.querySelector("#galerieSubTwo").addEventListener("mouseover", function () {
            document.querySelector("#galerieSubMenuTwo").style.display = "block";
        });
        document.querySelector("#galerieSubMenuTwo").addEventListener("mouseover", function () {
            document.querySelector("#galerieSubMenuTwo").style.display = "block";
        });
        document.querySelector("#galerieSubTwo").addEventListener("mouseleave", function () {
            document.querySelector("#galerieSubMenuTwo").style.display = "none";
        });
        document.querySelector("#galerieSubMenuTwo").addEventListener("mouseleave", function () {
            document.querySelector("#galerieSubMenuTwo").style.display = "none";
        });
    }, []);

    function classToggler() {
        console.log(document.querySelectorAll(".menuItem"));
        Array.from(document.querySelectorAll(".menuItem")).map((e) => {
            e.classList.remove("active-link");
        });
        this.classList.add("active-link");
    }

    document.addEventListener("DOMContentLoaded", function (event) {
        Array.from(document.querySelectorAll(".menuItem")).map((e) => {
            e.addEventListener("click", classToggler);
        });
    });

    // classToggler();

    return (
        <div className="menu mb-8">
            {/* <div id="test">Home</div> */}
            <div className="container-fluid menuBorder">
                <div className="container">
                    <div className="row menu">
                        <div className="col-3 d-flex align-items-center">
                            <Link to="/">
                                {/* <img src={logo} alt="" /> */}
                                <div className="menuItem home">Home</div>
                            </Link>
                        </div>
                        <div className="col-8 d-flex justify-content-between align-items-center">
                            <div className="menuWrap">
                                <Link to="/acryl">
                                    <div id="galerieSub" className="menuItem">
                                        Galerie
                                    </div>
                                </Link>
                                <div id="galerieSubMenu" className="subMenu">
                                    <Link to="/acryl">
                                        <div className="menuItem">Acryl</div>
                                    </Link>
                                    <Link to="/monotypien">
                                        <div className="menuItem">Monotypie</div>
                                    </Link>
                                    <Link to="/oel">
                                        <div className="menuItem">Öl / Öl-Pastell</div>
                                    </Link>
                                    <Link to="/aquarell">
                                        <div className="menuItem"> Aquarell & mehr</div>
                                    </Link>
                                </div>
                            </div>

                            <Link to="/blog">
                                <div className="menuItem">News</div>
                            </Link>
                            <Link to="/ausstellungen">
                                <div className="menuItem">Ausstellungen</div>
                            </Link>
                            <Link to="/biografie">
                                <div className="menuItem">Biografie</div>
                            </Link>
                            <div className="menuWrap">
                                <Link to="/notizen">
                                    <div id="galerieSubTwo" className="menuItem">
                                        Worte
                                    </div>
                                </Link>
                                <div id="galerieSubMenuTwo" className="subMenu">
                                    <Link to="/notizen">
                                        <div className="menuItem">Notizen</div>
                                    </Link>
                                    <Link to="/fundstuecke">
                                        <div className="menuItem">Fundstücke</div>
                                    </Link>
                                    <Link to="/berichte">
                                        <div className="menuItem">Berichte</div>
                                    </Link>
                                </div>
                            </div>
                            <HashLink to="#kontakt">
                                <div className="menuItem">Kontakt</div>
                            </HashLink>
                        </div>
                        <div className="col-1 d-flex justify-content-end align-items-center">
                            <a href="https://www.instagram.com/oberlikburtscherbri/">
                                <img className="instagram" src={instagram} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Menu;
