import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import GalleryModule from "./galleryModule";
import BlogOverview from "./blog_overview";
import AusstellungView from "./ausstellung_view";
import Success from "./success";
import { isMobile } from "react-device-detect";

import Signature from "./img/unterschrift_thinner_1.svg";

function Home() {
    const API_CALL = "https://www.oberlik-burtscher.at/wp/wp-json/wp/v2/pages/";

    const [content, setContent] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [boxHeight, setBoxHeight] = useState(0);
    let [success, setSuccess] = useState(false);

    const textRef = useRef();

    useEffect(() => {
        async function fetchData() {
            document.querySelector("#more").classList.add("noShow");
            // You can await here
            fetch(API_CALL)
                .then((response) => response.json())
                .then((result) => {
                    const data = result;
                    console.log(data);
                    console.log(data[2]);
                    console.log(data[2].acf.bildbiomobile);
                    document.querySelector("#more").classList.remove("noShow");
                    document.querySelector("#spinner").classList.add("noShow");

                    document.querySelector("#mainRow").classList.remove("noShow");
                    if (isMobile) {
                        document.getElementById("bioPic").style.backgroundImage =
                            "url(" + data[2].acf.bildbiomobile.url + ")";
                    } else {
                        document.getElementById("bioPic").style.backgroundImage = "url(" + data[2].acf.bild_bio + ")";
                    }
                    // document.getElementById("bioPic").style.backgroundImage = "url(" + data[2].acf.bild_bio + ")";
                    document.getElementById("mainHeader").innerHTML = data[2].acf.header;
                    document.getElementById("bioContentStart").innerHTML = data[2].acf.bio_text_startseite;
                    Array.from(document.getElementsByClassName("overview")).map((e) => {
                        e.style.height = e.clientWidth + "px";
                    });
                    document.getElementById("acrylOverview").style.backgroundImage =
                        "url(" + data[2].acf.acryl_bild.url + ")";
                    document.getElementById("monotypienOverview").style.backgroundImage =
                        "url(" + data[2].acf.monotypien_bild.url + ")";
                    document.getElementById("oelOverview").style.backgroundImage =
                        "url(" + data[2].acf.ol_bild.url + ")";
                    document.getElementById("verschiedenesOverview").style.backgroundImage =
                        "url(" + data[2].acf.verschiedenes_bild.url + ")";
                })
                .then(() => {
                    console.log("LOADED");
                    setLoaded(true);
                });
            // const response = await fetch(API_CALL);

            // console.log(data);
            // setContent(data);

            // ...
        }
        fetchData();

        // if (window.location.href.split("?")[1] == "success") {
        //     setSuccess(true)
        // }
        console.log(success);
    }, []);

    useEffect(() => {
        console.log(textRef.current.clientHeight);
        setBoxHeight(textRef.current.clientHeight);
    }, [loaded]);

    return (
        <div className="Home">
            <div className="container">
                <div id="spinner" className="spinnerWrapper d-flex justify-content-center h-100">
                    <div class="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div id="mainRow" className="row">
                    <div className="col-lg-6 col-md-6 col-sm-4 col-12">
                        {/* <div className={`${isMobile ? "mobileBio" : "desktopBio"}`}>
                            <img src={Signature} alt="" />
                        </div> */}
                        <div id="bioPic" />
                    </div>
                    <div className="col-lg-5 col-md-6 col-sm-8 col-12" ref={textRef}>
                        <h2 className="mb-5" id="mainHeader"></h2>
                        <div id="bioContentStart"></div>
                    </div>
                </div>
            </div>
            <div id="more">
                <GalleryModule />
                {/* <BlogOverview /> */}
                <AusstellungView />
            </div>
        </div>
    );
}

export default Home;
