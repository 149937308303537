import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link } from "react-router-dom";

function Kontakt() {
    const API_CALL = "https://www.oberlik-burtscher.at/wp/wp-json/wp/v2/kontakt_text";
    const API_CALL2 = "https://www.oberlik-burtscher.at/wp/wp-json/wp/v2/adresse";

    const [content, setContent] = useState([]);
    const [content2, setContent2] = useState(null);

    let successor = () => {
        if (window.location.href.split("?")[1] == "success") {
            return (
                <p id="successMessage" className="mt-4 font-weight-bold">
                    Danke für Ihre Nachricht!
                </p>
            );
        } else {
            return <p id="successMessage"></p>;
        }
    };

    useEffect(() => {
        async function fetchData() {
            // You can await here
            const response = await fetch(API_CALL);
            const response2 = await fetch(API_CALL2);
            const data = await response.json();
            const data2 = await response2.json();
            console.log(data, data2);
            setContent(data);
            setContent2(data2[0]);
            document.getElementById("kontaktText").innerHTML = data[0].acf.text;
            document.getElementById("adresse").innerHTML = data2[0].content.rendered;
            // ...
        }
        fetchData();
        console.log("useeffect");
    }, []);

    // document.addEventListener("DOMContentLoaded", function(event) {

    //     function validateEmail() {
    //     if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(document.querySelector("#kontaktEmail").value))
    //         {
    //            return true
    //         }
    //      }
    //     function validateMessage() {
    //     if (document.querySelector("#kontaktMessage").value.length > 1)
    //         {
    //            return true
    //         }
    //      }

    //     document.querySelector("#submit").addEventListener("click", function(e) {
    //         // e.preventDefault();
    //         document.querySelector("#errorMessage").style.display = "none";
    //         if(validateEmail() && validateEmail()){
    //             console.log("geht")
    //         }
    //         else {
    //             console.log("geht net")
    //             document.querySelector("#errorMessage").style.display = "block";
    //         }
    //     })
    // });

    return (
        <div className="kontakt mt-10 " id="kontakt">
            <div className="container-fluid grey">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div id="kontaktText"></div>
                            <hr className="gold" />
                            <div id="adresse"></div>
                            {/* <p> Brigitte Oberlik-Burtscher</p>
                            <p>
                                AT - 1190 Wien Dennweg 43/4
                                <br />
                                AT - 3053 Brand-Laaben Pyrath 3
                            </p>
                            <p>
                                {" "}
                                mobil: +43 676 490 8866
                                <br />
                                mail: <strong>brigitte[at]oberlik-burtscher.at</strong>{" "}
                            </p> */}
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <form method="post" action="sendmail.php">
                                <input id="kontaktEmail" name="email" placeholder="Email ..." type="email" />
                                <textarea
                                    id="kontaktMessage"
                                    name="message"
                                    placeholder="Nachricht"
                                    rows="4"
                                    cols="50"
                                />
                                <button id="submit" className="kontaktBtn" type="submit" name="submit">
                                    abschicken
                                </button>
                                <p id="errorMessage">Füllen Sie alle korrekt Felder aus</p>
                                {successor()}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="subFooter container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col d-flex py-3">
                            <Link to="/impressum">
                                <div className="submenuItem">Impressum</div>
                            </Link>
                            <Link to="/datenschutzerklaerung">
                                <div className="submenuItem">Datenschutzerklärung</div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Kontakt;
