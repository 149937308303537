import React, { useState, useEffect } from "react";
import "./App.css";

function Breadcrumbs(props) {
    return (
        <div id="breadcrumbs" className="breadcrumbs">
            <a className="mr-2" href="/blog">
                Blog
            </a>
            -{" "}
            <a className="ml-2 activeLink" href={props.slug}>
                {props.slug}
            </a>
        </div>
    );
}

export default Breadcrumbs;
