import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link } from "react-router-dom";

function Worte() {
    const API_CALL = "https://www.oberlik-burtscher.at/wp/wp-json/wp/v2/worte/";

    const [content, setContent] = useState("");

    useEffect(() => {
        async function fetchData() {
            // You can await here
            fetch(API_CALL)
                .then((response) => response.json())
                .then((result) => {
                    const data = result;
                    console.log(data);
                    setContent(data[0].acf.worte_1);
                    document.querySelector("#spinner").classList.add("noShow");
                    // document.querySelector("#spinner").classList.add("noShow");
                    // // You can await here
                    // document.getElementById("img1").style.backgroundImage =
                    //     "url(" + data[0].acf.bild1.url + ")";
                    // document.getElementById("bioHolder").innerHTML =
                    //     data[0].content.rendered;

                    Array.from(document.querySelectorAll(".worteMenu")).map((e, i) => {
                        switch (i) {
                            case 0:
                                e.innerHTML = data[0].acf.menu1;
                                break;
                            case 1:
                                e.innerHTML = data[0].acf.menu2;
                                break;
                            case 2:
                                e.innerHTML = data[0].acf.menu3;
                                break;
                            case 3:
                                e.innerHTML = data[0].acf.menu4;
                                break;
                        }
                        e.addEventListener("click", function () {
                            switch (i) {
                                case 0:
                                    setContent(data[0].acf.worte_1);
                                    break;
                                case 1:
                                    setContent(data[0].acf.worte_2);
                                    break;
                                case 2:
                                    setContent(data[0].acf.worte_3);
                                    break;
                                case 3:
                                    setContent(data[0].acf.worte_4);
                                    break;
                            }
                        });
                    });

                    // document.getElementById("datum").innerHTML = data[0].acf.datum;
                    // document.getElementById("details").innerHTML = data[0].acf.text;
                    // document.getElementById("datum2").innerHTML =
                    //     data[0].acf.datum_copy;
                    // document.getElementById("details2").innerHTML =
                    //     data[0].acf.text_copy2;
                    // ...
                });
        }

        fetchData();
        console.log("useeffect");
    }, []);

    return (
        <div className="worte" id="worte">
            <div className="container">
                <div className="row">
                    <div className="col-4">
                        <div className="worteMenu"></div>
                        <div className="worteMenu"></div>
                        <div className="worteMenu"></div>
                        <div className="worteMenu"></div>
                    </div>

                    <div className="col-8">
                        <div id="worte1">{content}</div>
                        <div id="spinner" className="spinnerWrapperGallery d-flex justify-content-center h-100">
                            <div className="lds-ellipsis">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Worte;
