import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import "./style/main.css";
import "./style/mobile.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./home";
import GalleryModule from "./galleryModule";
import Acryl from "./acryl";
import Monotypien from "./monotypien";
import Oel from "./oel";
import Aquarell from "./aquarell";
import Menu from "./menu";
import MobileMenu from "./mobilemenu";
import Kontakt from "./kontakt";
import Biografie from "./biografie";
import Blog from "./blog";
import Blogdetail from "./blogdetail";
import Worte from "./worte";
import Datenschutz from "./datenschutzerklaerung";
import Impressum from "./impressum";
import Ausstellungen from "./ausstellungen";
import Fundstuecke from "./fundstuecke";
import Notizen from "./notizen";
import Berichte from "./berichte";

function App() {
    return (
        <Router>
            <div className="App">
                <Menu />
                <MobileMenu />
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/acryl" component={Acryl} />
                    <Route path="/monotypien" component={Monotypien} />
                    <Route path="/oel" component={Oel} />
                    <Route path="/aquarell" component={Aquarell} />
                    <Route path="/biografie" component={Biografie} />
                    <Route path="/ausstellungen" component={Ausstellungen} />
                    <Route path="/blog" exact component={Blog} />
                    <Route path="/blog/:id" component={Blogdetail} />
                    <Route path="/fundstuecke" component={Fundstuecke} />
                    <Route path="/notizen" component={Notizen} />
                    <Route path="/berichte" component={Berichte} />
                    <Route path="/worte" component={Worte} />
                    <Route path="/datenschutzerklaerung" component={Datenschutz} />
                    <Route path="/impressum" component={Impressum} />
                </Switch>
                <Kontakt />
            </div>
        </Router>
    );
}

export default App;
