import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Link } from "react-router-dom";

function Berichte() {
    const API_CALL = "https://www.oberlik-burtscher.at/wp/wp-json/wp/v2/pages/";

    const [content, setContent] = useState("");

    useEffect(() => {
        async function fetchData() {
            // You can await here
            fetch(API_CALL)
                .then((response) => response.json())
                .then((result) => {
                    const data = result;
                    console.log(data);
                    document.querySelector("#spinner").classList.add("noShow");

                    document.querySelector("#fund").innerHTML = data[6].content.rendered;

                    // document.getElementById("datum").innerHTML = data[0].acf.datum;
                    // document.getElementById("details").innerHTML = data[0].acf.text;
                    // document.getElementById("datum2").innerHTML =
                    //     data[0].acf.datum_copy;
                    // document.getElementById("details2").innerHTML =
                    //     data[0].acf.text_copy2;
                    // ...
                });
        }

        fetchData();
        console.log("useeffect");
    }, []);

    return (
        <div className="worte" id="worte">
            <div className="container">
                <div className="row mb-5 worte-menu">
                    <div className="col submenu d-flex justify-content-between w-100 phoneWords">
                        <Link to="/notizen">
                            <div className="galleryLink">Notizen</div>
                        </Link>
                        <Link to="/fundstuecke">
                            <div className="galleryLink">Fundstücke</div>
                        </Link>
                        <Link to="/berichte">
                            <div className="galleryLink activeMenu">Berichte</div>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <h2 class="mb-5">Berichte</h2>
                        <div id="fund"></div>

                        <div id="spinner" className="spinnerWrapperGallery d-flex justify-content-center h-100">
                            <div className="lds-ellipsis">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Berichte;
